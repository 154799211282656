import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  HttpClientModule,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, switchMap, retry } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { getUrlScheme } from '@angular/compiler';
import * as CryptoJS from 'crypto-js';

export interface IUtilityService {
  getByUrl: (url: string, options?: any) => Observable<HttpResponse<any>>;
  postByUrl: (url: string, payload: any) => Observable<HttpResponse<any>>;
}
const dealerPostBody = [
  { propName: 'column', value: 'brandcode' },
  { propName: 'column', value: 'busname' },
  { propName: 'column', value: 'lbusname' },
  { propName: 'column', value: 'dlrpa' },
  { propName: 'column', value: 'zip' },
  { propName: 'column', value: 'url' },
  { propName: 'column', value: 'lurl' },
  { propName: 'column', value: 'dcproviderfl' },
  { propName: 'column', value: 'dcliveplatform' }
];

@Injectable({
  providedIn: 'root'
})
export class UtilityService implements IUtilityService {
  azure_token =
    '?sv=2018-03-28&ss=b&srt=sco&sp=rwdlac&se=2028-12-20T02:47:06Z&st=2018-12-19T18:47:06Z&spr=https&sig=NhhGwkqwDaf5FLu%2FRJ09FUaGC8AYm4DyQvV6Q7uGjjQ%3D';
  loginToken = localStorage.getItem('token');
  csvFileName: any;
  public updatepaCode: Subject<any> = new Subject<any>();
  public onTriggerNotification: Subject<any> = new Subject<any>();
  public onSelectedDcoPassData: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  public onSelectedDcoCreatePassData: Subject<any> = new Subject<any>();
  public pacodeHasChanged: Subject<any> = new Subject();
  public brandHasChanged: Subject<any> = new Subject();
  public hasFilteredResults: Subject<any> = new Subject<any>();
  selectedDealer: any;
  public selectedAdmDealer: any;
  selectedUsersforDealer: any;
  selectedUser: any;
  selectedAdvDealer: any = {
    pacode: '',
    dealer_name: '',
    brand: '',
    active: 0,
    partner_switch: 1,
    partner: '',
    mcmid: '',
    id_activate_date: '',
    goLive_date: '',
    cancel_date: '',
    hist_ids: [],
    curr_ids: {
      fflood: '',
      lflood: '',
      adv_calltracking_by: '',
      bing_id: '',
      google_adwords_id: '',
      google_analytics_id: '',
      advertiser_parent_id: '',
      advertiser_child_id: '',
      globalSiteId: '',
      activity_by: '',
      activity_date: '',
      amznid: '',
      amznprtcl: '',
      amznsrc: '',
      dt_script_src: '',
      google_account_id: '',
      fbpixel_id: ''
    }
  };
  selectedTemplate: any;
  selectedOfferIdx = '';
  userForm: any;
  isNewUser: boolean;
  isNewDealer: boolean;
  isNewAdvDealer: boolean = true;
  users: any;
  results: any;
  csvData: any;
  exportInformation: any;
  public searchAllModels: any;
  private _listners = new Subject<any>();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.results = [];
  }
  token: any;
  public getByUrl(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public startLoadingSpinner(): void {
    this.spinner.show();
  }

  public stopLoadingSpinner(): void {
    this.spinner.hide();
  }

  public getOemOffers(environment: any, pacode: string): Observable<any> {
    const url = `${environment.apiPostOemOffers}/${pacode}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public postOemOffers(payload: any, environment: any): Observable<any> {
    const url = `${environment.apiPostOemOffers}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http.post(url, payload, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getAccountNumber(
    environmentUrl: any,
    accountNumber: string
  ): Observable<any> {
    const url = `${environmentUrl.apigetAccountNumber
      }?customerId=${accountNumber}`;
    const httpOptions = {};
    return this.http.get(url, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getAdgroups(
    environmentUrl: any,
    accountNumber: string,
    modelName: string
  ): Observable<any> {
    const url = `${environmentUrl.apigetAdgroup
      }?customerId=${accountNumber}&filter=${modelName}`;
    const httpOptions = {};
    return this.http.get(url, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public submitToGoogle(
    environmentUrl: any,
    accountNumber: string,
    adGroupId: string,
    head1: string,
    head2: string,
    head3: string,
    desc1: string,
    desc2: string,
    url: string,
    status: string,
    label: string,
    lblResFromGoogle: string
  ) {
    desc1 = desc1.replace(/&/g, '~');
    desc2 = desc2.replace(/&/g, '~');
    head1 = head1.replace(/&/g, '~');
    head2 = head2.replace(/&/g, '~');
    head3 = head3.replace(/&/g, '~');
    desc1 = desc1.replace(/\+/g, '`');
    desc2 = desc2.replace(/\+/g, '`');
    head1 = head1.replace(/\+/g, '`');
    head2 = head2.replace(/\+/g, '`');
    head3 = head3.replace(/\+/g, '`');
    desc1 = desc1.replace(/\#/g, 'œ');
    desc2 = desc2.replace(/\#/g, 'œ');
    head1 = head1.replace(/\#/g, 'œ');
    head2 = head2.replace(/\#/g, 'œ');
    head3 = head3.replace(/\#/g, 'œ');
    desc1 = desc1.replace(/\!/g, 'ž');
    desc2 = desc2.replace(/\!/g, 'ž');
    head1 = head1.replace(/\!/g, 'ž');
    head2 = head2.replace(/\!/g, 'ž');
    head3 = head3.replace(/\!/g, 'ž');
    var submitToGoogleUrl = '';
    if (label && lblResFromGoogle) {
      submitToGoogleUrl = `${environmentUrl.submitToGoogleUrl
        }?customerId=${accountNumber}&adGroupId=${adGroupId}&head1=${head1}&head2=${head2}&head3=${head3}&desc1=${desc1}&desc2=${desc2}&url=${url}&status=${status}&labelResourceName=${lblResFromGoogle}`;
    } else {
      lblResFromGoogle = '';
      submitToGoogleUrl = `${environmentUrl.submitToGoogleUrl
        }?customerId=${accountNumber}&adGroupId=${adGroupId}&head1=${head1}&head2=${head2}&head3=${head3}&desc1=${desc1}&desc2=${desc2}&url=${url}&status=${status}&labelResourceName=${lblResFromGoogle}`;
    }
    var encodedUrl = encodeURI(submitToGoogleUrl);
    encodedUrl = encodedUrl.replace(/~/g, '%26');
    encodedUrl = encodedUrl.replace(/%60/g, '%2b');
    encodedUrl = encodedUrl.replace(/%C5%93/g, '%23');
    encodedUrl = encodedUrl.replace(/%C5%BE/g, '%21');
    const httpOptions = {
      //  headers: new HttpHeaders({ 'Authorization': localStorage.getItem('jwtToken') })
    };
    return this.http.get(encodedUrl, { observe: 'body', responseType: 'text' });
  }

  public getByPacode(environmentUrl: any, pacode: string): Observable<any> {
    const searchUrl = `${environmentUrl.apiDcoUrl}${pacode}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(searchUrl, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getNewDco(environmentUrl: any, pacode: string): Observable<any> {
    const searchUrl = `${environmentUrl.apiNewDcoUrl}${pacode}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(searchUrl, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getNewDcoAllAds(environmentUrl: any): Observable<any> {
    const searchUrl = `${environmentUrl.apiPostNewDcoUrl}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(searchUrl, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getSelectOffers(recored: any, environment: any): Observable<any> {
    const zipcode = recored.postalcode;
    const url = `${environment.offerInfo}make=${recored.make}&model=${recored.model
      }&postalCode=${zipcode.substr(0, 5)}&year=${recored.year}`;

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  public getSelectedOffers(environment: any): Observable<any> {
    const paCode = localStorage.getItem('pacode');
    const url = environment.offerApprovalApi + '/' + paCode;
    return this.http.get(url).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public getOffersDataByPaCode(environment: any, paCode): Observable<any> {
    const url = environment.offerApprovalApi + '/' + paCode;
    return this.http.get(url).pipe(
      map(this.extractData),
      catchError(this.handleError),
      retry(3)
    );
  }

  public getDcos(pacode, environment: any): Observable<any> {
    const url = `${environment.apiDcoUrl}${pacode}`;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public postDco(data, environment: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http
      .post(environment, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public postNewDco(data, environment: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http
      .post(environment, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getNsLookup(data, environment: any): Observable<any> {
    return this.http.get(environment, data).pipe(catchError(this.handleError));
  }

  getPlacementsAPI(data, environment): Observable<any> {
    const url = `${environment.apiPlacementsUrl}?pacode=${data.pacode}&make=${data.make
      }&model=${data.model}&size=${data.size}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getPlacementsAllSizesAPI(data, environment): Observable<any> {
    const url = `${environment.apiPlacementsUrl}?pacode=${data.pacode}&make=${data.make
      }&model=${data.model}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getPromisePlacementsAPI(data, environment): Observable<any> {
    const url = `${environment.apiPlacementsUrl}?pacode=${data.pacode}&make=${data.make
      }&model=${data.model}&size=${data.size}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };

    return this.http.get(url, httpOptions);
  }

  public postOfferApproval(data, environment: any): Observable<any> {
    const url = environment.offerApprovalApi;
    const dealerAuthDecrypt = CryptoJS.AES.decrypt('U2FsdGVkX185nGbKNN3MdBefeQ1YfNm9/biMV+ocmPo=', localStorage.getItem('passEcode'));
    const dealerAuth = dealerAuthDecrypt.toString(CryptoJS.enc.Utf8);
    const httpOptions = {
      headers: new HttpHeaders({
        dealerauth: dealerAuth,
        username: localStorage.getItem('username')
      })
    };
    return this.http.post(url, data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public getOffer(recored, environment: any): Observable<any> {
    const zipcode = recored.postalcode;
    let url =
      environment.offerInfo +
      'make=' +
      recored.make +
      '&model=' +
      recored.model;
    url += '&postalCode=' + zipcode.substr(0, 5) + '&year=' + recored.year;

    return this.http.get(url).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public postByUrl(url: string, payload: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.post(url, payload, httpOptions).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private extractXmlData(res: Response) {
    return res;
  }

  getDealer(environment, dealerPaCode): Observable<any> {
    const url = `${environment.apiDealerUrl}/${dealerPaCode}`;
    let udlDeltaTokenDecrypt = CryptoJS.AES.decrypt('U2FsdGVkX1+WcBOboPpKFmr4Zbzh6UGwe7+uDIk5i++KbXp1F2sAS99lpzPnesE95mrnJu2Q25YIV03RItGvelW8QWStzxvDewbpzMU0tPWNSE0qip/gn9uXOJM785X64TWUuC00i/WQ6qyDZKv51YQhWSZgeluCIpKZsqBMkBOkREaUiFexW/XOzeleqTZeuoivGEVLTmmlM+WCdXmH04siw7tufcIe87PVVMBTHvyRezOsozVBPlnBMuPSIrYDfEAm3wGUOHyit6Rt0qeiayxSniLuufKIoD4dwbGcOz200x6H+yp2SpU1BQXrYA0QZLtr2bvd+fw/0ZP62/NUmOaYtNHOPU7sp/3Dv/fvYHAJr53waNunhNHUUMfPDa2UXPlL4bCjrSWB4H086na+9vgvWaOwJAHjYrWKu9+gfkJWtZ73eF6ZF/CSEfpDjYZ+Y0Du+jJlUTUIC/3Hz8TjNCjNbMfx3OoWW6jSfWXk2/wxU7X1qyrfZjpHZJGDtP2nDm2PUnw2mZuiQnWfu++ydNy8om6zW2F1Fmu8ir39BggKrEEL0hAmyAygrO8fqniWUADAc1NBBe1qTObL3q0iUa6+X5yt66xnir8Zj7rE8jjee/wB/t5BqMW21/Lf41eF', localStorage.getItem('passEcode'));
    const udlDeltaToken = udlDeltaTokenDecrypt.toString(CryptoJS.enc.Utf8);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: udlDeltaToken })
    };
    return this.http.post(url, dealerPostBody, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError),
      retry(3)
    );
  }

  getDealers(environment: any): Observable<any> {
    const url = `${environment.apiDealersUrl}/`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getDealersData(environment: any, dealerPaCode: string): Observable<any> {
    const url = `${environment.apiDealersUrl}/pacode/${dealerPaCode}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getDealerData(
    environment: any,
    dealerPaCode: string,
    dealerBrand: string
  ): Observable<any> {
    const url = `${environment.apiDealersUrl
      }/pacode/${dealerPaCode}/${dealerBrand}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  getAllUsers(environment: any): Observable<any> {
    const url = `${environment.apiAllUsers}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  //validateUserByEmail(environment: any, email: String): Observable<any> {
    //const url = `${environment.apiAllUsers}/validateEmail?email=${email}`;
    //const httpOptions = {
      //headers: new HttpHeaders({
       // Authorization: localStorage.getItem('jwtToken')
      //})
   // };
    // const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' }

    /*return this.http.get<any>(url).pipe(map(response => {
     const user = response;
     return user;
 }));*/

   // return this.http.get<any>(url).pipe(
     // map(response => {
       // var result = {};
       // result = response;
       // return result;
      //})
    //);
    //return result;
  //}

  getAllDealers(environment: any): Observable<any> {
    const url = `${environment.apiAllDealers}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  patchUser(environment, user, data): Observable<any> {
    const url = `${environment.apiPatchUserUrl}/${user}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        username: localStorage.getItem('username')
      })
    };

    //Fix for ST-1784: replaced http 'patch' with http 'put', because http 'patch' isn't working across all the
    //Networks (i.e., Ford/FD vs Non Ford/FD Networks), but kept the function name intact
    return this.http
      .put(url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  postNewUser(environment, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http
      .post(environment.apiPostUserUrl, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  postNewDealer(environment, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http
      .post(environment.apiPostDealerUrl, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public postDealer(pacode: string, environmentUrl: any): Observable<any> {
    const searchUrl = `${environmentUrl.apiDealerUrl}/${pacode}`;
    let udlDeltaTokenDecrypt = CryptoJS.AES.decrypt('U2FsdGVkX1+WcBOboPpKFmr4Zbzh6UGwe7+uDIk5i++KbXp1F2sAS99lpzPnesE95mrnJu2Q25YIV03RItGvelW8QWStzxvDewbpzMU0tPWNSE0qip/gn9uXOJM785X64TWUuC00i/WQ6qyDZKv51YQhWSZgeluCIpKZsqBMkBOkREaUiFexW/XOzeleqTZeuoivGEVLTmmlM+WCdXmH04siw7tufcIe87PVVMBTHvyRezOsozVBPlnBMuPSIrYDfEAm3wGUOHyit6Rt0qeiayxSniLuufKIoD4dwbGcOz200x6H+yp2SpU1BQXrYA0QZLtr2bvd+fw/0ZP62/NUmOaYtNHOPU7sp/3Dv/fvYHAJr53waNunhNHUUMfPDa2UXPlL4bCjrSWB4H086na+9vgvWaOwJAHjYrWKu9+gfkJWtZ73eF6ZF/CSEfpDjYZ+Y0Du+jJlUTUIC/3Hz8TjNCjNbMfx3OoWW6jSfWXk2/wxU7X1qyrfZjpHZJGDtP2nDm2PUnw2mZuiQnWfu++ydNy8om6zW2F1Fmu8ir39BggKrEEL0hAmyAygrO8fqniWUADAc1NBBe1qTObL3q0iUa6+X5yt66xnir8Zj7rE8jjee/wB/t5BqMW21/Lf41eF', localStorage.getItem('passEcode'));
    const udlDeltaToken = udlDeltaTokenDecrypt.toString(CryptoJS.enc.Utf8);
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: udlDeltaToken })
    };
    return this.http.post(searchUrl, dealerPostBody, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public postAdvDealer(environment, data): Observable<any> {
    const searchUrl = environment.apiAdvDealerUrl;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http
      .post(searchUrl, data, httpOptions)
      .pipe(catchError(this.handleError1));
  }

  handleError1(error: any) {
    if (error.status == 400) {
      if (error.error) {
        return throwError(error.error);
      } else {
        return throwError(
          `${error.error.Response}: ${error.statusText}\n please try again.`
        );
      }
    }
  }

  public patchAdvDealer(environment, data): Observable<any> {
    const searchUrl = environment.apiAdvDealerUrl;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http
      .put(searchUrl, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getAzureImages(environmentUrl: any) {
    const getUrl = `${environmentUrl}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/xml'
      })
    };

    return this.http.get(getUrl, { observe: 'body', responseType: 'text' });
  }

  public showNetworkError(e: any): void {
    this.toastrService.error(`${e}`, 'Network Error:', {
      positionClass: 'toast-bottom-center',
      tapToDismiss: true,
      closeButton: true,
      progressBar: true
    });
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(
      `${error.error.Response}: ${error.statusText}\n please try again.`
    );
  }

  /**  Upload zip on azure */
  public uploadZip(data, zip, environment: any): Observable<any> {
    // console.log(data.size);
    // console.log('the zip', zip);

    let url = environment.uploadAzureZip + zip;
    url += '?sv=2018-03-28&ss=bf&srt=sco&sp=rwdlac&se=2028-12-13T02:07:23Z&';
    url +=
      'st=2018-12-12T18:07:23Z&spr=https&sig=ViEcu%2B17PCWLsXDJlOOwaR8Npuzi7t%2FBvSscN0kxte8%3D';
    //   console.log(url);

    const httpOptions = {
      headers: new HttpHeaders({
        'x-ms-type': 'file',
        'x-ms-version': '2018-03-28',
        'x-ms-content-length': data.size,
        'x-ms-blob-type': 'BlockBlob',
        'Content-type': 'application/zip'
      })
    };

    return this.http
      .put(url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  /**  Upload Images on azure */
  public uploadImages(data, fileSize, fileName, environment): Observable<any> {
    const url = environment.uploadImagesUrl + fileName + this.azure_token;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-ms-version': '2018-03-28',
        'x-ms-content-length': fileSize,
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': 'image/*',
        'x-ms-type': 'file'
      })
    };

    return this.http.put(url, data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  /**  Send Selected Offers for Ad Approval  */
  public adApproval(data, environment: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    const url = environment.apiPostSelectedOfferUrl;
    return this.http.post(url, data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public localhostRouteTo(appName: string, environment: any): void {
    switch (appName) {
      case 'dco':
        location.href = environment.localhostDco;
        return;
      case 'dashboard':
        location.href = environment.localhostDashboard;
        return;
      default:
        return;
    }
  }

  public addDefaultPlacements(data, environment): Observable<any> {
    const url = `${environment.apiPlacementsUrl}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };

    return this.http.post(url, data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public deleteExistingPlacements(data, environment): Observable<any> {
    const url = `${environment.apiPlacementsUrl}/${data}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken')
      })
    };

    return this.http.delete(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  public createLabelResource(
    environment: any,
    customerId: string,
    label: string
  ): Observable<any> {
    const url = `${environment.apiCreateLabel
      }/?customerId=${customerId}&label=${label}`;
    return this.http.get(url, { observe: 'body', responseType: 'text' });
  }

  public getUdlApiStatus(environment: any): Observable<any> {
    const url = `${environment.udlApiStatusUrl}`;
    return this.http.get(url, { observe: 'body', responseType: 'text' });
  }

  public getDcoApiStatus(environment: any): Observable<any> {
    const url = `${environment.dcoApiStatusUrl}`;
    return this.http.get(url, { observe: 'body', responseType: 'text' });
  }

  public postAdvFLs(environment, data): Observable<any> {
    const flUrl = environment.apiAdvDealerFLsUrl;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username')
      })
    };
    return this.http
      .post(flUrl, data, httpOptions)
      .pipe(catchError(this.handleError1));
  }

  public getPlacementData(placementId): Observable<any> {
    const getPlacementDataUrl = `https://qaapi.fdcampaignstudio.com/dcm/getPlacement?placementId=${placementId}`;
    return this.http
      .get(getPlacementDataUrl)
      .pipe(catchError(this.handleError1));
  }

  public updatePlacementData(placementData): Observable<any> {
    const getPlacementDataUrl = `https://qaapi.fdcampaignstudio.com/dcm/updatePlacement`;
    return this.http
      .post(getPlacementDataUrl, placementData)
      .pipe(catchError(err => throwError(err)));
  }

  public postAdvFloodlights(environment, data): Observable<any> {
    const floodlightUrl =
      'https://qaapi.fdcampaignstudio.com/dcm/uploadFloodlights';
    // const floodlightUrl = "https://qaapi.fdcampaignstudio.com/dcm/uploadFloodlights"
    // const floodlightUrl = "http://localhost:8080/uploadFloodlights"
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwtToken'),
        userName: localStorage.getItem('username'),
        'Content-type': 'application/json; charset=utf-8'
      })
    };
    //console.log("postAdvFloodlights() ==> Request Input");
    //console.log(JSON.stringify(data));
    var json = JSON.stringify(data);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', floodlightUrl, false);
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    xhr.send(json);

    var response = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      console.log('successful');
      //console.log(response);
    } else {
      console.log('failed');
    }
    return response;
  }

  public handleActionFor(actionType: string): void {
    switch (actionType) {
      case 'floodlights':
        this.router.navigateByUrl('floodlights');
        break;
      case 'users':
        this.router.navigateByUrl('users');
        break;
      case 'dealers':
        this.router.navigateByUrl('dealers');
        break;
      case 'dealer-advantage':
        this.router.navigateByUrl('dealers/advantage');
        break;
      case 'cost':
        this.router.navigateByUrl('cost');
        break;
      case 'pmalookup-tool':
          this.router.navigateByUrl('pmalookup');
          break;
      default:
        this.router.navigateByUrl('dealers/boarding');
    }
  }
}
